import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

/**
 * Inspired by StyleManager from https://github.com/angular/material.angular.io
 */
@Injectable({
  providedIn: 'root'
})
export class StyleService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Sets the stylesheet with the specified key.
   */
  setStyle(key: string, href: string, insertBefore?: boolean): void {
    const el = this.getLinkElementForKey(key, insertBefore);
    const elHref = el.getAttribute('href');
    if (elHref !== href) {
      el.setAttribute('href', href);
    }
  }

  /**
   * Removes the stylesheet with the specified key.
   */
  removeStyle(key: string) {
    const existingLinkElement = this.getExistingLinkElementByKey(key);
    if (existingLinkElement) {
      this.document.head.removeChild(existingLinkElement);
    }
  }

  private getLinkElementForKey(key: string, insertBefore?: boolean): Element {
    return this.getExistingLinkElementByKey(key) || this.createLinkElementWithKey(key, insertBefore);
  }

  private getExistingLinkElementByKey(key: string): Element | null {
    return this.document.head.querySelector(`link[rel='stylesheet'].${this.getClassNameForKey(key)}`);
  }

  private createLinkElementWithKey(key: string, insertBefore?: boolean): HTMLLinkElement {
    const linkEl = document.createElement('link');
    linkEl.setAttribute('rel', 'stylesheet');
    linkEl.classList.add(this.getClassNameForKey(key));
    if (insertBefore) {
      this.document.head.insertBefore(linkEl, this.document.head.firstChild);
    } else {
      this.document.head.appendChild(linkEl);
    }
    return linkEl;
  }

  private getClassNameForKey(key: string): string {
    return `ibricks-${key}`;
  }
}
